import Layout from "../components/Layout";
import {
  Center,
  Text,
  Divider,
  VStack,
  Heading,
  Circle,
} from "@chakra-ui/react";
import Anchor from "../components/Anchor";
import { SEO } from "../components/SEO";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";

const HowThisWorksPage = () => {
  const { t } = useTranslation();

  return (
    <Layout blurBackground>
      <SEO title={t("HowThisWorksPage.title")} />
      <Center w="full" h="full">
        <VStack maxW="lg" spacing={3} align="center">
          <Heading fontSize="3xl" textAlign={"center"}>
            {t("HowThisWorksPage.title")}
          </Heading>
          <Text fontSize="md">{t("HowThisWorksPage.description")}</Text>
          <Circle size={10} fontSize="3xl" textAlign={"center"} bg="red.100">
            1
          </Circle>
          <Text fontSize="md">
            {t("HowThisWorksPage.step1")}
          </Text>
          <Circle size={10} fontSize="3xl" textAlign={"center"} bg="red.100">
            2
          </Circle>
          <Text fontSize="md">
            {t("HowThisWorksPage.step2")}
          </Text>
          <Circle size={10} fontSize="3xl" textAlign={"center"} bg="red.100">
            3
          </Circle>
          <Text fontSize="md">
            {t("HowThisWorksPage.step3")}
          </Text>
          <Circle size={10} fontSize="3xl" textAlign={"center"} bg="red.100">
            4
          </Circle>
          <Text fontSize="md">{t("HowThisWorksPage.step4")}</Text>
          <Circle size={10} fontSize="3xl" textAlign={"center"} bg="red.100">
            5
          </Circle>
          <Text fontSize="md">Done!</Text>
          <Divider />

          <Anchor to="/">
            ...
            {t("common.go_back_to_home")}
          </Anchor>
        </VStack>
      </Center>
    </Layout>
  );
};

export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default HowThisWorksPage;
